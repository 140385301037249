import { useContext, useState } from 'react';

/* styles */
import style from '@styles/pdp/ColorPicker.module.scss';

/* context */
import context from '@context/pdp';
import { setVariant } from '@context/pdp/actions';

export default function ColorPicker() {
    const { state, dispatch } = useContext(context)
    const colors = state.step.product.colors
    const [selectedColor, setSelectedColor] = useState(state.step.product.variant?.code || colors[0]?.code || null)

    const getBackgroundStyle = (swatchColors) => 
        swatchColors.length === 1 
            ? { backgroundColor: swatchColors[0] } 
            : swatchColors.length === 2 
                ? { background: `linear-gradient(45deg, ${swatchColors[0]} 0%, ${swatchColors[0]} 50%, ${swatchColors[1]} 50%, ${swatchColors[1]} 100%)` }
                : swatchColors.length === 3
                    ? { background: `linear-gradient(45deg, ${swatchColors[0]} 0%, ${swatchColors[0]} 33.3333%, ${swatchColors[1]} 33.3333%, ${swatchColors[1]} 66.6667%, ${swatchColors[2]} 66.6667%, ${swatchColors[2]} 100%)` }
                    : {}

    const getItemClassName = (variant) => 
        `${style.colorPickerCircle} ${selectedColor === variant ? style.active : ''}`.trim()

    const handleClick = (color) => {
        if (selectedColor !== color.code) {
            setSelectedColor(color.code)
            setVariant({ state, dispatch }, state.step.product.partNumber, color, state.bundle.products[state.step.index].prepend)
        }
    }

    return <div className={style.colorPicker}>
        {colors.map((color) => (
            <div key={color.code} onClick={() => handleClick(color)}>
                <button className={style.colorPickerButton}>
                    <span className={getItemClassName(color.code)}>
                        <span style={getBackgroundStyle(color.swatchColors)}></span>
                    </span>
                </button>
            </div>
        ))}
    </div>
}