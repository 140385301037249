export function filterBundleList(item, activeFilters) {
  if (item.associated) {
    for (const [property, values] of Object.entries(activeFilters)) {
      if (property === 'collection' && values && !values.includes(item.associated)) {
        return false
      }
    }
  }

  const bundlesToFilter = item.bundles || [item]

  const result = bundlesToFilter.every(bundle => {
    for (const [property, values] of Object.entries(activeFilters)) {
      if (!values || values.length === 0) {
        continue
      }
      const lowerCaseValues = values.map(value => value.toLowerCase())

      if (property === 'accessories') {
        const isAccessories = bundle.accessoriesTranslated.toLowerCase()
        if (!lowerCaseValues.includes(isAccessories)) {
          return false
        }
      } else if (property === 'activity') {
        const bundleActivities = bundle.activity ? bundle.activity.split(';').map(activity => activity.toLowerCase()) : []
        if (!lowerCaseValues.every(value => bundleActivities.includes(value))) {
          return false
        }
      } else if (property === 'collection') {
        continue
      } else if (property === 'gender') {
        const bundleGender = bundle.gender ? bundle.gender.toLowerCase() : ''
        const isGenderMatch = lowerCaseValues.includes(bundleGender)
        const isUnisexIncluded = bundleGender === 'unisex' && (lowerCaseValues.includes('men') || lowerCaseValues.includes('women'))

        if (!isGenderMatch && !isUnisexIncluded) {
          return false
        }
      } else {
        if (!bundle[property] || !lowerCaseValues.includes(bundle[property].toLowerCase())) {
          return false
        }
      }
    }
    return true
  })

  return result
}

export function getFiltersFromBundles(bundles, t) {
  const filters = {
    gender: new Set(),
    activity: new Set(),
    accessories: new Set()
  }

  bundles.forEach(bundleOrGroup => {
    const bundleList = Array.isArray(bundleOrGroup.bundles) ? bundleOrGroup.bundles : [bundleOrGroup]

    bundleList.forEach(bundle => {
      if (bundle.gender) {
        filters.gender.add(bundle.gender)
      }

      if (bundle.activity) {
        const activities = bundle.activity.split(';')
        activities.forEach(activity => {
          filters.activity.add(activity.toLowerCase())
        })
      }

      const isAccessories = bundle.accessories_bundle === 1 ? 'accessories' : 'not accessories'
      filters.accessories.add(isAccessories)
    })
  })

  return {
    gender: Array.from(filters.gender).map(gender => t(`${gender}`)),
    activity: Array.from(filters.activity).map(activity => t(`${activity}`)),
    accessories: Array.from(filters.accessories).map(accessory => t(`${accessory}`))
  }
}

export function getFiltersFromCollections(collections, t) {
  const filters = {
    gender: new Set(),
    collection: new Map()
  }

  collections.forEach(collection => {
    if (collection.bundles) {
      const bundles = typeof collection.bundles === 'string'
        ? JSON.parse(collection.bundles)
        : collection.bundles
      if (Array.isArray(bundles)) {
        bundles.forEach(bundle => {
          if (bundle.gender) filters.gender.add(bundle.gender)
        })
      }
    }

    if (collection.associated) {
      const genericValue = collection.associated
      const localizedLabel = (collection.name && collection.name.trim() !== '')
        ? collection.name
        : genericValue
      filters.collection.set(genericValue, localizedLabel)
    }
  })

  return {
    gender: Array.from(filters.gender),
    collection: Array.from(filters.collection.entries()).map(([value, label]) => ({ value, label }))
  }
}

export const getActivableFilters = (bundles) => {
    const bundleFilters = getFiltersFromBundles(bundles)
    const activableFilters = []

    for (const filter in bundleFilters) {
        if (typeof bundleFilters[filter] === 'object') bundleFilters[filter].map(value => activableFilters.push(value))
        if (typeof bundleFilters[filter] === 'boolean' && bundleFilters[filter]) activableFilters.push('accessories')
    }
    return activableFilters
}

export const getActiveFilters = (filter, active) => {
    const res = { ...active }

    if (typeof filter.value !== 'boolean') {
        const activeByProperty = active[filter.property] || {}
        const newActiveByProperty = !activeByProperty.length ? [filter.value] : activeByProperty.includes(filter.value) ? activeByProperty.filter(value => value !== filter.value) : [...activeByProperty, filter.value]
        if (newActiveByProperty.length) res[filter.property] = newActiveByProperty
        else delete res[filter.property]
    } else {
        if(active[filter.property])
            delete res[filter.property]
        else res[filter.property] = true
    }
    return res
}

export const getQSActiveFilters = (filter, active) => {
    const res = { ...active }
    if (typeof filter.value !== 'boolean') {
        const activeByProperty = active[filter.property] || {}
        const newActiveByProperty = !activeByProperty.length ? [filter.value] : [...activeByProperty, filter.value]
        if (newActiveByProperty.length) res[filter.property] = newActiveByProperty
    } else {
        res[filter.property] = true
    }
    return res
}

export const validateQueryFilters = (queryFilters, allFilters) => {
    return Object.keys(queryFilters).every((key) => {
      if (key === "accessories") return true
      else return allFilters[key]?.includes(queryFilters[key])
    })
}

export function getActiveFiltersFromURL(search, filters) {
  const params = new URLSearchParams(search)
  const activeFilters = {}

  params.forEach((value, key) => {
      if (filters[key]) {
          activeFilters[key] = value.split(',').map(val => val)
      }
  })

  return activeFilters
}
  
export function updateURLWithFilters(activeFilters, navigate) {
  const params = new URLSearchParams()

  Object.entries(activeFilters).forEach(([key, values]) => {
    if (Array.isArray(values)) {
      const uniqueValues = [...new Set(values.map(value => value))]
      
      // Exclude unisex but keep filtering functionalities
      if (key === 'gender' && (uniqueValues.includes('men') || uniqueValues.includes('women'))) {
        params.set(key, uniqueValues.filter(value => value !== 'unisex').join(','))
      } else {
        params.set(key, uniqueValues.join(','))
      }
    } else {
      params.set(key, values)
    }
  })

  navigate(`?${params.toString()}`)
}