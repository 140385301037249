import { useNavigate } from 'react-router-dom'
import { toggleFilter, setFilterLoading } from '@context/plp/actions'
import style from '@styles/plp/Filters.module.scss'
import { updateURLWithFilters } from '@utils/listing'

const activityMapping = {
  "hiking": "HIKING",
  "mountaineering": "MOUNTAINEERING",
  "climbing": "CLIMBING",
  "training": "TRAINING",
  "lifestyle": "LIFESTYLE",
  "mountain biking": "MOUNTAIN-BIKING",
  "skiing and snowboarding": "SKIING",
  "ski touring": "SKI-TOURING",
  "via ferrata": "VIA-FERRATA",
  "trail running": "TRAIL-RUNNING",
  "camping": "CAMPING",
}

const accessoriesMapping = {
  "accessories": "ACCESSORIES",
  "not accessories": "NOT-ACCESSORIES",
}

export default function ActiveFilter({ property, value, label, state, dispatch, t }) {
  const navigate = useNavigate()
  const lowerCaseValue = typeof value === 'string' ? value.toLowerCase() : value
  const displayText = label || value

  const handleRemove = () => {
    toggleFilter({ state, dispatch }, { property, value }, t)

    const updatedFilters = {
      ...state.filters.active,
      [property]: state.filters.active[property].filter(v => v !== value)
    }

    if (updatedFilters[property].length === 0) {
      delete updatedFilters[property]
    }

    const currentScrollY = window.scrollY
    window.scrollTo(0, currentScrollY + 1)
    requestAnimationFrame(() => {
      window.scrollTo(0, currentScrollY)
    })

    setFilterLoading({ dispatch }, false)
    updateURLWithFilters(updatedFilters, navigate)
  }

  function getTranslatedValue(value, property) {
    if (property === 'gender') {
      const translationKey = `plp.FILTERS.${value.toUpperCase()}`
      return t(translationKey, value)
    }
    else if (property === 'activity') {
      const normalizedKey = activityMapping[value.toLowerCase()] || value.toUpperCase()
      const translationKey = `plp.FILTERS.${normalizedKey}`
      return t(translationKey, value)
    }
    else {
      const normalizedKey = accessoriesMapping[value.toLowerCase()] || value.toUpperCase()
      const translationKey = `plp.FILTERS.${normalizedKey}`
      return t(translationKey, value)
    }
  }

  const translatedValue = getTranslatedValue(value, property)

  // Condition to show unisex only if it is selected alone
  const shouldDisplayUnisex = property !== 'gender' || lowerCaseValue !== 'unisex' || 
    (state.filters.active.gender.length === 1 && state.filters.active.gender[0].toLowerCase() === 'unisex')

  return (
    <>
      {shouldDisplayUnisex && (
        <div className={style.activeFilter}>
          <span>{property === 'collection' ? displayText : translatedValue}</span>
          <button onClick={handleRemove} className={style.removeFilter}>
            &times;
          </button>
        </div>
      )}
    </>
  )
}