import { useContext, useState, useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useLocation } from "react-router-dom"
import "../../i18n"

/* styles */
import style from '@styles/plp/Filters.module.scss'

/* context */
import context from "@context/plp"
import { toggleFilter, clearAllFilters, setSorting, setFilterLoading, setFiltering } from "@context/plp/actions"

/* utils */
import { updateURLWithFilters } from "@utils/listing"
import { onApplyFilter } from "@utils/analytics"

/* components */
import Dropdown from '../generic/Dropdown'
import ActiveFilter from '../generic/ActiveFilter'

/* config */
import { sortOptions, getTranslations } from '@utils/const'

/* helpers */
import {getFilterValue} from '@utils/helpers'

export default function Filters() {
  const { state, dispatch } = useContext(context)
  const { t } = useTranslation("translation")
  const navigate = useNavigate()
  const location = useLocation()

  const isCollectionView = Boolean(state.collectionGroup?.length && !state.bundleGroup?.length)

  const filterRef = useRef(null)
  const [selectedSortOption, setSelectedSortOption] = useState(0)
  const [activeFilter, setActiveFilter] = useState({ sort: true })
  const [showFilters, setShowFilters] = useState(false)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)

  const sortOptionsConst = sortOptions(t)
  const translationsConst = getTranslations(t)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    showFilters ? document.body.classList.add('overlay') : document.body.classList.remove('overlay')

    return () => {
      document.body.classList.remove('overlay')
    }
  }, [showFilters])

  const handleSort = (sortOptionId) => {
    setSelectedSortOption(sortOptionId)
    setSorting({ dispatch, state }, sortOptionId)
  }

  const handleFilterSelect = (property, value) => {
    setFiltering({ dispatch }, true)
    const isActive = state.filters.active[property]?.includes(value)
    
    const updatedFilters = {
      ...state.filters.active,
      [property]: isActive
        ? state.filters.active[property].filter(v => v !== value)
        : [...(state.filters.active[property] || []), value]
    }
  
    if (isActive) {
      if (updatedFilters[property].length === 0) delete updatedFilters[property]
    } else {
      onApplyFilter(value)
      if (property === 'gender') {
        const currentGenderFilters = updatedFilters.gender || []
        if (currentGenderFilters.includes('men') || currentGenderFilters.includes('women')) {
          updatedFilters.gender = [...new Set([...currentGenderFilters, 'unisex'])]
        }
      }
    }
  
    toggleFilter({ state, dispatch }, { property, value }, t)
    setFilterLoading({ dispatch }, false)
    updateURLWithFilters(updatedFilters, navigate)
  }

  const handleRemove = (property, value) => {
    setFiltering({ dispatch }, true)
    toggleFilter({ state, dispatch }, { property, value }, t)

    const updatedFilters = {
      ...state.filters.active,
      [property]: state.filters.active[property].filter(v => v !== value)
    }

    if (updatedFilters[property].length === 0) {
      delete updatedFilters[property]
    }

    setFilterLoading({ dispatch }, false)
    updateURLWithFilters(updatedFilters, navigate)
  }

  const handleFilterExpand = (property) => {
    setActiveFilter((prev) => isMobile 
      ? { ...prev, [property]: !prev[property] }
      : { [property]: !prev[property] && true }
    )
  }

  const handleClickOutside = (event) => {
    if (!isMobile && filterRef.current && !filterRef.current.contains(event.target)) {
      if (Object.keys(activeFilter).length > 0) {
        setActiveFilter({})
      }
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [activeFilter])

  const translate = (key, option = null) => {
    const translations = translationsConst
  
    if (option) {
      if (key === 'gender') {
        const upper = option.trim().toUpperCase()
        return translations[key]?.options?.[upper] || upper
      } else {
        return translations[key]?.options?.[option] || option
      }
    }
    return translations[key]?.label || key
  }

  const collectionMap = {}
  if (Array.isArray(state.filters.all.collection)) {
    state.filters.all.collection.forEach(obj => {
      collectionMap[obj.value] = obj.label
    })
  }

  const selectedSortLabel = sortOptionsConst.find(option => option.id === selectedSortOption)?.label
  const filterTitle = isCollectionView ? t("plp.FILTERS.COLLECTIONS-TITLE") : t("plp.FILTERS.BUNDLES-TITLE")

  return (
    <div className={style.wrapper}>
      <div className={style.container}>
        {/* Desktop */}
        <div ref={filterRef} className={`${style.filters} ${style.desktopFilters}`}>
          {Object.keys(state.filters.all).map((key) => (
            state.filters.all[key].length > 0 && (
              <div key={key} className={style.filter}>
                <button 
                  className={`${style.filterButton} ${activeFilter[key] ? style.active : ''}`} 
                  onClick={() => handleFilterExpand(key)}>
                  {translate(key)}
                  <span className={`${style.caret} ${activeFilter[key] ? style.active : ''}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="15" height="15">
                      <path d="M12 17.414 3.293 8.707l1.414-1.414L12 14.586l7.293-7.293 1.414 1.414L12 17.414z"/>
                    </svg>
                  </span>
                </button>
                {activeFilter[key] && (
                  <div className={style.filterOptions}>
                    {state.filters.all[key].map((option, index) => {
                      let displayText = ""
                      let filterValue = ""
                    
                      if (typeof option === "object" && option !== null) {
                        displayText = option.label
                        filterValue = option.value
                      } else {
                        displayText = translate("collection", option)
                        filterValue = option
                      }
                      
                      if (key === 'gender') {
                        if (displayText.length > 0) {
                          displayText = displayText.charAt(0).toUpperCase() + displayText.slice(1).toLowerCase()
                        }
                        const genderFilter = state.filters.active.gender || []
                        const isUnisexHidden = (genderFilter.includes("men") || genderFilter.includes("women")) &&
                          filterValue === 'unisex'
                        if (isUnisexHidden) return null // Non renderizzare l'opzione "unisex" se è nascosta
                      }
                      
                      const isActive = state.filters.active[key]?.includes(filterValue)
                      return (
                        <div key={index} className={style.filterOptionContainer}>
                          <button
                            className={`${style.filterOption} ${isActive ? style.active : ''}`}
                            onClick={ isActive
                              ? () => handleRemove(key, getFilterValue(key, option))
                              : () => handleFilterSelect(key, getFilterValue(key, option))
                            }
                          >
                            {key === 'collection' ? displayText : translate(key, option)}
                          </button>
                        </div>
                      )
                    })}
                  </div>
                )}
              </div>
            )
          ))}
        </div>

        {/* Mobile */}
        <div className={`${style.mobileFilters} ${showFilters ? style.active : ''}`}>
          {Object.keys(state.filters.all).length > 0 && (
            <button 
              className={style.filterButton}
              onClick={() => setShowFilters(!showFilters)}>
                {t("plp.FILTERS.FILTERS")}
                <span className={style.caret}></span>
            </button>
          )}

          <div className={style.overlay} style={{ display: showFilters ? 'block' : 'none' }} onClick={() => setShowFilters(false)}></div>
          <div className={`${style.filterPanel} ${showFilters ? style.active : ''}`}>
            <div className={style.filterPanelContainer}>
              <div className={style.filterPanelHeader}>
                <span>{filterTitle}</span>
                <button className={style.filterPanelClose} onClick={() => setShowFilters(false)}></button>
              </div>
              {Object.keys(state.filters.active).length > 0 && (
                <div className={style.activeFilters}>
                  <div>
                    {Object.entries(state.filters.active).flatMap(([property, values]) =>
                      values.map((value, index) => {
                        let displayText = value
                    
                        if (property === 'collection' && collectionMap[value]) {
                          displayText = collectionMap[value]
                        }
                    
                        return (
                          <ActiveFilter
                            key={`${property}-${index}`}
                            property={property}
                            value={value}
                            label={displayText}
                            state={state}
                            dispatch={dispatch}
                            t={t}
                          />
                        )
                      })
                    )}
                  </div>
                  <button className={style.clearAll} onClick={() => {
                    clearAllFilters({ dispatch })
                    navigate(location.pathname)
                  }}>
                    {t("plp.FILTERS.CLEAR-ALL")}
                  </button>
                </div>
              )}
              {!isCollectionView && (
                <div className={style.filter}>
                  <button
                    className={`${style.filterButton} ${activeFilter['sort'] ? style.active : ''}`}
                    onClick={() => handleFilterExpand('sort')}
                  >
                    {t('plp.FILTERS.SORTING')}
                    <span className={`${style.caret} ${activeFilter['sort'] ? style.active : ''}`}>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
                        <path d="M12 17.414 3.293 8.707l1.414-1.414L12 14.586l7.293-7.293 1.414 1.414L12 17.414z"/>
                      </svg>
                    </span>
                  </button>

                  {activeFilter['sort'] && (
                    <div className={style.filterOptions}>
                      {sortOptionsConst.map((option) => (
                        <div key={option.id} className={style.filterOptionContainer}>
                          <button
                            className={`${style.filterOption} ${style.filterOptionSort} ${selectedSortOption === option.id ? style.active : ''}`}
                            onClick={() => handleSort(option.id)}
                          >
                            {option.label}
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
              {Object.keys(state.filters.all).map((key) => (
                state.filters.all[key].length > 0 && (
                  <div key={key} className={style.filter}>
                    <button 
                      className={`${style.filterButton} ${activeFilter[key] ? style.active : ''}`} 
                      onClick={() => handleFilterExpand(key)}>
                      {translate(key)}
                      <span className={`${style.caret} ${activeFilter[key] ? style.active : ''}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
                          <path d="M12 17.414 3.293 8.707l1.414-1.414L12 14.586l7.293-7.293 1.414 1.414L12 17.414z"/>
                        </svg>
                      </span>
                    </button>
                    {activeFilter[key] && (
                      <div className={style.filterOptions}>
                        {state.filters.all[key].map((option, index) => {
                          let displayText = ""
                          let filterValue = ""
                        
                          if (typeof option === "object" && option !== null) {
                            displayText = option.label
                            filterValue = option.value
                          } else {
                            displayText = translate(key, option)
                            filterValue = option
                          }

                          const isUnisexHidden = (key === 'gender' && 
                            (state.filters.active.gender?.includes('men') || 
                            state.filters.active.gender?.includes('women')) && 
                            option === 'unisex')
      
                          if (isUnisexHidden) {
                            return null // Do not render the "Unisex" option
                          }

                          const isActive = state.filters.active[key]?.includes(filterValue)
                          return (
                            <div key={index} className={style.filterOptionContainer}>
                              <button
                                className={`${style.filterOption} ${isActive ? style.active : ''}`}
                                onClick={
                                  isActive
                                    ? () => handleRemove(key, filterValue)
                                    : () => handleFilterSelect(key, filterValue)
                                }
                              >
                                {key === 'collection' ? displayText : translate(key, option)}
                              </button>
                            </div>
                          )
                        })}
                      </div>
                    )}
                  </div>
                )
              ))}
              <div className={style.filterPanelFooter}>
                <button className={style.filterPanelCta} onClick={() => setShowFilters(false)}>
                  {t("plp.FILTERS.SEE-RESULTS")}
                </button>
                <button className={style.clearAll} onClick={() => {
                  clearAllFilters({ dispatch })
                  navigate(location.pathname)
                }}>
                  {t("plp.FILTERS.CLEAR-ALL")}
                </button>
              </div>
            </div>
          </div>
        </div>

        {!isCollectionView && (
          <div className={style.sort}>
            <Dropdown 
              label={selectedSortLabel} 
              options={sortOptionsConst} 
              onSelect={handleSort} 
            />
          </div>
        )}
      </div>

      {Object.keys(state.filters.active).length > 0 && (
        <div className={style.activeFilters}>
          <div>
            {Object.entries(state.filters.active).flatMap(([property, values]) =>
              values.map((value, index) => {
                let displayText = value

                if (property === 'collection' && collectionMap[value]) {
                  displayText = collectionMap[value]
                }

                return (
                  <ActiveFilter
                    key={`${property}-${index}`}
                    property={property}
                    value={value}
                    label={displayText}
                    state={state}
                    dispatch={dispatch}
                    t={t}
                  />
                )
              })
            )}
          </div>
          <button className={style.clearAll} onClick={() => {
              clearAllFilters({ dispatch })
              navigate(location.pathname)
            }}>
              {t("plp.FILTERS.CLEAR-ALL")}
          </button>
        </div>
      )}
    </div>
  )
}